<template>
<div class="mobile-menu-container">
    <div class="mobile-menu-wrapper">
        <span class="mobile-menu-close" @click="hideMobileMenu">
            <i class="icon-close"></i>
        </span>
        
        <nav class="mobile-nav" >

            <ul class="mobile-menu">
                 <li>
                    <a class="sf-with-ul languageClass" style="color:white;">{{$t("components.mobilemenu.language")}}</a>

                    <ul>
                        <li v-for="(lang, index) of selectedLang" :key="index">
                            <a href="#" @click.prevent="lang_change(lang.code)">{{lang.name}}</a>
                        </li>

                    </ul>
                </li>

            </ul>
        </nav>
        
        <div class="social-icons">
            <a class="social-icon" :href="merchantDetails.facebook_url" target="_blank" title="Facebook" v-if="merchantDetails.facebook_url !== null && merchantDetails.facebook_url !== ''">
                <i class="icon-facebook-f"></i>
            </a>
            <a class="social-icon" :href="merchantDetails.twitter_url" target="_blank" title="Twitter" v-if="merchantDetails.twitter_url !== null && merchantDetails.twitter_url !== ''">
                <i class="icon-twitter"></i>
            </a>
            <a class="social-icon" :href="merchantDetails.instagram_url" target="_blank" title="Instagram" v-if="merchantDetails.instagram_url !== null && merchantDetails.instagram_url !== ''">
                <i class="icon-instagram"></i>
            </a>
            <a class="social-icon" :href="merchantDetails.youtube_url" target="_blank" title="Youtube" v-if="merchantDetails.youtube_url !== null && merchantDetails.youtube_url !== ''">
                <i class="icon-youtube"></i>
            </a>
        </div>
    </div>
</div>
</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';
import Tabs from '~/components/elements/Tabs';
import { mobileMenu } from '~/utilities/common.js';

export default {
    data: function () {
        return {
            searchTerm: '',
            MerchantSettings: '',
            selectedLang: '',
            merchantDetails: '',
            customerDetails: '',
            environment:''
        };
    },
    mounted: function () {
        this.environment = this.$store.state.sessionStorage.environment
        this.customer_id = this.$store.state.sessionStorage.customer_id;
        console.log("customer_id##", this.customer_id)
        mobileMenu();
        this.merchantSettings();
        this.getMerchantDetails();
       
        // this.getCustomerSetting();

    },
    methods: {
                                  //<---------------- logout Function ------------------>

        // logout: function () {
        //     if(this.msal){
        //         this.msal.logout();
        //     }
            
        //     var encrypt = this.$route.query.encrypted ? this.$route.query.encrypted : 'N'
        //     if(encrypt === 'y'){
        //         this.$store.commit("sessionStorage/assignuserLoginSuccessfull", false);
        //     }else{
        //         this.$store.commit("sessionStorage/assignuserLoginSuccessfull", false);
        //         window.location.replace('/')
        //     }

        // },
        //    msalConfig(customerSettingDetails) {
        //     console.log("customerSettingDetails", customerSettingDetails);
        //     if (customerSettingDetails) {
        //         const msalConfig = {
        //             auth: {
        //                 clientId: customerSettingDetails.client_id,
        //                 authority: `https://${customerSettingDetails.tenant_name}.b2clogin.com/${customerSettingDetails.tenant_name}.onmicrosoft.com/${customerSettingDetails.user_flow_name}`,
        //                 knownAuthorities: [`${customerSettingDetails.tenant_name}.b2clogin.com`],
        //                 redirectUri: customerSettingDetails.azure_redirect_url //customerSettingDetails.azure_redirect_url,
        //             },
        //             cache: {
        //                 cacheLocation: 'localStorage',
        //                 storeAuthStateInCookie: true,
        //             },
        //         };

        //         const msalInstance = new PublicClientApplication(msalConfig);
        //         console.log('MSAL instance directly in component:', msalInstance);
        //         this.msal = msalInstance;
        //     } else {
        //         console.error('customerSettingDetails is not available');
        //     }
        // },

        faroLog(data) {
            var message = "application:" + "Mz-WALLET, environment:" + `${this.environment}` + ", level:" + `${data.level}` + ", module:" + `CartMenu Page (${data.api})` + ", msg:" + `${data.msg}` + ", time:" + `${new Date()}`
            return message
        },

        //  <=============================== Get Customer Setting API function for wallet ==============================>>>>

        getCustomerSetting: function () {
            var url = `/api/mzapps/customersetting?timestamp=${new Date().getTime()}`;
            this.$axios.$get(url).then(response => {
                this.customerSettingDetails = response.customer_setting;
                console.log("customerSettingDetails", this.customerSettingDetails);
                if (this.customerSettingDetails) {
                    this.msalConfig(this.customerSettingDetails)
                    console.log("this.customerSettingDetails", this.customerSettingDetails);
                }
            }).catch(e => {
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'customersetting Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },


        hideMobileMenu: function () {
            document.querySelector('body').classList.remove('mmenu-active');
        },
        

        // <================================ Merchant Settings Api function ====================================>

        merchantSettings: function () {
            var url = `/api/mzapps/settings?country_code=${this.country}&timestamp=${new Date().getTime()}`
            this.$axios.$get(url).then(response => {
                console.log("merchantSettings ", response)
                this.MerchantSettings = response;
                if (this.MerchantSettings) {
                    if (this.MerchantSettings.selected_language) {
                        var selected_language = this.MerchantSettings.selected_language;
                        var selectLanguage = [];
                        if (selected_language.kr) {
                            var language_object_kr = {};
                        }
                        if (selected_language.en) {
                            var language_object_en = {};
                        }
                        if (selected_language.zh_Hant) {
                            var language_object_zh_Hant = {};
                        }
                        if (selected_language.zh_Hans) {
                            var language_object_zh_Hans = {};
                        }
                        if (selected_language.id) {
                            var language_object_id = {};
                        }
                        if (selected_language.th) {
                            var language_object_th = {};
                        }
                        if (selected_language.ja) {
                            var language_object_ja = {};
                        }
                        if (selected_language.kr) {
                            language_object_kr.name = "Korean (한국어)";
                            language_object_kr.code = "kr";
                            language_object_kr.code_align = "F";
                            selectLanguage.push(language_object_kr);
                        }

                        if (selected_language.en) {
                            language_object_en.name = "English";
                            language_object_en.code = "en";
                            language_object_en.code_align = "A";
                            selectLanguage.push(language_object_en);
                        }

                        if (selected_language.zh_Hant) {
                            language_object_zh_Hant.name = "Chinese Traditional (繁體中文)";
                            language_object_zh_Hant.code = "zh_Hant";
                            language_object_zh_Hant.code_align = "C";
                            selectLanguage.push(language_object_zh_Hant);
                        }

                        if (selected_language.zh_Hans) {
                            language_object_zh_Hans.name = "Chinese Simplified (簡體中文)";
                            language_object_zh_Hans.code = "zh_Hans";
                            language_object_zh_Hans.code_align = "D";
                            selectLanguage.push(language_object_zh_Hans);
                        }

                        if (selected_language.id) {
                            language_object_id.name = "Bahasa Indonesia";
                            language_object_id.code = "id";
                            language_object_id.code_align = "B";
                            selectLanguage.push(language_object_id);
                        }

                        if (selected_language.th) {
                            language_object_th.name = "Thai (ภาษาไทย)";
                            language_object_th.code = "th";
                            language_object_th.code_align = "G";
                            selectLanguage.push(language_object_th);
                        }

                        if (selected_language.ja) {
                            language_object_ja.name = "Japanese (日本語)";
                            language_object_ja.code = "ja";
                            language_object_ja.code_align = "E";
                            selectLanguage.push(language_object_ja);
                        }

                        this.selectedLang = selectLanguage;
                        this.selectedLang.sort((a, b) => a.code_align.localeCompare(b.code_align));
                    }
                }

                console.log('this.selectedLang', this.selectedLang);
            }).catch(e => {
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'settings Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },

        // <<<<<<<<<<<<<<<<<<<<<<< language change function >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        lang_change: function (lang_code) {
            this.$store.commit('sessionStorage/assignSelectLang', lang_code);
            window.location.reload()
            // MultiLang(lang_code)
        },

        // <================================ Merchant Detail Api function ====================================>

        getMerchantDetails: function () {
            var url = `/api/mzapps/merchant/${this.$store.state.sessionStorage.merchant_id}?country_code=${this.country}&timestamp=${new Date().getTime()}`
            this.$axios.$get(url).then(response => {
                this.merchantDetails = response.merchant;
                console.log('FoootermerchantDetails', this.merchantDetails);
                // document.title = `Official E-Voucher Store | ${this.merchantDetails.merchant_name}`
            }).catch(e => {
                var error_params = {
                    msg: e.data ? e.data.message : e,
                    api: 'merchant Api',
                    level: 'error'
                }
                var error_text = this.faroLog(error_params)
                faro.api.pushError(new Error(error_text))
            })
        },
    }
};
</script>

<style>
.cust-data {
    padding: 2em !important;
}

.pre-des-mob {
    word-wrap: break-word !important;
    color: #fff !important;
}

.summary-title-mob {
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: 0;
    padding-bottom: 0 !important;
    border-bottom: none !important;
    margin-bottom: 0.5rem !important;
    color: #fff !important;
}

#mob-btn {
    border-color: #fff !important;
    color: #fff !important;
}

#mob-btn:hover {
    border-color: #fff !important;
    color: #fff !important;
    background-color: #333333 !important;
}

.qr-img {
    padding: 2em !important;
}
.item-count{
    display: flex;
    align-items: center;
    justify-content: center;
    right: 1rem;
    top: 50%;
    z-index: 10;
    width: 3rem;
    height: 3rem;
    font-size: 1.2rem;
    color: #fff;
    margin-top: -1.5rem;
    
   
   
}
.mobile-menu span:not(.mmenu-btn):not(.tip) {
    position: absolute !important;
}
</style>
